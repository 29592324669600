@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
}

#root {
  min-height: 100vh;
}

.bg-light {
  background: rgb(255, 255, 255);
  background: linear-gradient(
    31deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(233, 236, 255, 1) 58%,
    rgba(211, 248, 255, 1) 100%
  );
}

.bg-dark {
  background: rgb(13, 14, 24);
  background: linear-gradient(
    31deg,
    rgba(13, 14, 24, 1) 0%,
    rgba(12, 29, 31, 1) 50%,
    rgba(5, 14, 32, 1) 100%
  );
}

.herogradient {
  background: rgb(0, 18, 198);
  background: linear-gradient(
    31deg,
    rgba(0, 18, 198, 1) 0%,
    rgba(97, 55, 168, 1) 50%,
    rgba(180, 14, 110, 1) 100%
  );
}

.herogradientdark {
  background: rgb(0, 27, 42);
  background: radial-gradient(
    circle,
    rgba(0, 27, 42, 1) 19%,
    rgba(6, 18, 34, 1) 63%,
    rgba(1, 13, 12, 1) 100%
  );
}
